import { Space } from "antd";
import Image from "next/image";
import Link from "next/link";
import { motion } from "framer-motion";
import React from "react";
import { LINK_GOOGLE } from "@/constant/types";

const menuList = [
  {
    label: "Home",
    href: "#banner",
  },
  {
    label: "About Us",
    href: "#about-us",
  },
  {
    label: "Services",
    href: "#service",
  },
  {
    label: "How it works",
    href: "#how-it-work",
  },
  {
    label: "Our Solutions",
    href: "#solution",
  },
  {
    label: "FAQ",
    href: "#FAQ",
  },
  {
    label: "Contact Us",
    href: LINK_GOOGLE,
    icon: (
      <Image src={"/icon-direction.svg"} width={13.5} height={13.5} className="object-cover" alt="icon" unoptimized />
    ),
  },
];

const Header = () => (
  <header className="header flex items-center">
    <Space className="w-full items-center justify-between">
      <Image className="object-cover" src="/logo.svg" width={168} height={45} alt="logo" />
      <div className="flex items-center gap-12">
        {menuList.map((i, index) => (
          <React.Fragment key={index}>
            {i.icon ? (
              <Link className="text-white text-base" href={i.href} target="_blank" rel="noopener noreferrer">
                <motion.div
                  transition={{ type: "spring", mass: 1, stiffness: 266.7, damping: 10 }}
                  whileHover={{ translateY: -5 }}
                >
                  <Space size={12} className="h-[56px] py-4 px-9 rounded-[60px] bg-primary cursor-pointer">
                    {i.label}

                    {i.icon}
                  </Space>
                </motion.div>
              </Link>
            ) : (
              <Link className="px-3 py-2 h-10 text-black-1 text-base nav-link rounded-lg" key={index} href={i.href}>
                {i.label}
              </Link>
            )}
          </React.Fragment>
        ))}
      </div>
    </Space>
  </header>
);

export default Header;
