import { Space, Typography } from "antd";
import classNames from "classnames";
import { motion } from "framer-motion";
import Image from "next/image";
import { useState } from "react";
import useResponsive from "../hooks/useResponsive";
import DisplaySize from "../utils/DisplaySize";

const lists = [
  {
    id: 1,
    question: "What is the pricing model for your product?",
    answer:
      "Our pricing model is tailored to suit the needs of businesses of all sizes. We offer flexible pricing plans based on factors such as the volume of reviews/ratings analyzed and additional features required. Contact our sales team to discuss a plan that fits your specific requirements.",
  },
  {
    id: 2,
    question: "How do you ensure the security of the data collected from internet?",
    answer:
      "We take data security seriously. At first, our platform employs industry-standard encryption protocols to safeguard all data collected from Google Reviews. Additionally, we adhere to strict privacy policies and regulations to ensure the confidentiality and integrity of your data.",
  },
  {
    id: 3,
    question: "What features do you offer to enhance the user experience of your product?",
    answer:
      "Our product is designed with user experience in mind. We offer an intuitive and user-friendly interface that makes it easy to navigate and analyze reviews/ratings from Google. Additionally, we provide customizable dashboards, real-time analytics, and personalized insights to enhance user engagement and satisfaction.",
  },
  {
    id: 4,
    question: "Does your product utilize ChatGPT in its AI infrastructure?",
    answer:
      "Yes, ChatGPT is indeed a component of our AI infrastructure. However, we don't solely rely on ChatGPT. In addition to ChatGPT, we've integrated several state-of-the-art GenAI models to enhance and diversify our AI capabilities. These models work in parallel with ChatGPT, enabling us to provide comprehensive and advanced solutions tailored to meet the diverse needs of our users.",
  },
];

const QuestionAndAnswer = () => {
  const [activeQuestion, setActiveQuestion] = useState<number | null>(null);
  const displaySize = useResponsive();

  return (
    <div
      id="FAQ"
      className="h-[760px] bg-black-1 flex flex-col justify-center items-center gap-[56px] py-[50px]"
    >
      <Typography.Title
        level={5}
        className="text-[42px] leading-[1.2] md:text-[64px] font-medium md:leading-[77px] text-center text-product-2 text-white m-0"
      >
        Question & Answers
      </Typography.Title>
      <Space direction="vertical" size={24} className="FQA px-3 md:px-0">
        {lists.map(i => (
          <motion.div
            key={i.id}
            whileHover={
              displaySize > DisplaySize.Tablet
                ? {
                    borderColor: "#16DBCC",
                    scale: 1.05,
                    transformOrigin: "left center",
                    transition: { type: "spring", mass: 1, stiffness: 99.99, damping: 15 },
                  }
                : undefined
            }
            animate={{ height: "auto", transition: { type: "spring", mass: 1, stiffness: 99.99, damping: 15 } }}
            onClick={() => setActiveQuestion(prev => (prev === i.id ? null : i.id))}
            style={{ borderColor: "#535353", height: "auto" }}
            className={classNames(
              "py-[10px] max-w-[370px] md:max-w-[700px] lg:max-w-[1086px] rounded-2xl bg-[#535353] flex flex-col gap-6 justify-between border text-2xl leading-6 text-white text-product-2 font-normal px-[37px]",
              { "absolute top-0 left-0 right-0 w-full h-auto z-50": activeQuestion === i.id },
            )}
          >
            <Space className="justify-between items-center">
              <Typography.Text className="text-lg md:text-2xl md:leading-[24px] text-left text-product-2 text-white">
                {i.question}
              </Typography.Text>
              <div className="w-[56px] h-[56px] bg-black-1 rounded-full flex items-center justify-center cursor-pointer transition-all">
                <Image
                  className={classNames({ "rotate-45": activeQuestion === i.id })}
                  src={"/plus-icon.svg"}
                  width={24}
                  height={24}
                  alt="icon"
                />
              </div>
            </Space>
            {activeQuestion === i.id && (
              <Typography.Text className="text-sm md:text-lg leading-[24px] text-left text-product-2 text-[#D0D0D0]">
                {i.answer}
              </Typography.Text>
            )}
          </motion.div>
        ))}
      </Space>
    </div>
  );
};

export default QuestionAndAnswer;
