import { LINK_APP } from "@/constant/types";
import { Space, Typography } from "antd";
import { motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
const Service = () => (
  <div
    id="service"
    className="service-bg h-[1000px] md:h-[1300px] lg:h-[926px] bg-[#FFFFFF] flex justify-center items-center lg:px-[50px]"
  >
    <div className="max-w-[1340px] flex flex-col-reverse lg:flex-row justify-center items-center gap-[54px]">
      <div className="basis-3/5 relative px-3 lg:px-0">
        <Image src={"/service-1.png"} width={766} height={685} className="object-cover" alt="bg" unoptimized />
        <Image
          src={"/service-icon.svg"}
          width={516}
          height={344}
          className="object-cover px-3 lg:px-0 absolute bottom-[-24.7%] left-[-1.5%]"
          alt="icon"
          unoptimized
        />
      </div>
      <div className="flex flex-col items-center lg:items-start gap-9 basis-2/5 px-3 lg:px-0">
        <Space direction="vertical" className="items-center lg:items-start">
          <Typography.Text className="text-base text-product-1 text-primary leading-6 font-bold uppercase">
            smark&apos;s ServICES
          </Typography.Text>
          <Typography.Title
            level={2}
            className="text-product-1 text-[42px] leading-[1.2] lg:text-[64px] text-black-1 lg:leading-[77px] m-0 font-medium text-center lg:text-start"
          >
            Digital Marketing Solutions
          </Typography.Title>
        </Space>
        <Typography.Text className="text-product-2 text-lg leading-[24px] text-black-2 text-justify lg:text-left">
          Explore our comprehensive suite of digital marketing services designed to meet the diverse needs of businesses
          in today&apos;s competitive landscape. From AI-powered content generation to targeted advertising campaigns,
          we offer a range of solutions to help you attract, engage, and retain customers. Discover how SMARK can help
          you elevate your marketing efforts and achieve your business goals.
        </Typography.Text>
        <Link target="_blank" rel="noopener noreferrer" href={LINK_APP}>
          <motion.div
            transition={{ type: "spring", mass: 1, stiffness: 266.7, damping: 10 }}
            whileHover={{ translateY: -5 }}
            className="flex items-center justify-center h-[56px] py-4 px-9 rounded-[60px] bg-primary text-white gap-3 cursor-pointer w-fit"
          >
            Start Achieving Your Business Goals
            <Image src={"/icon-direction.svg"} width={13.5} height={13.5} className="object-cover" alt="icon" />
          </motion.div>
        </Link>
      </div>
    </div>
  </div>
);
export default Service;
