import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const menuList = [
  {
    label: "Home",
    href: "#banner",
  },
  {
    label: "About Us",
    href: "#about-us",
  },
  {
    label: "Services",
    href: "#service",
  },
  {
    label: "How it works",
    href: "#how-it-work",
  },
  {
    label: "Our Solutions",
    href: "#solution",
  },
  {
    label: "FAQ",
    href: "#FAQ",
  },
  {
    label: "Contact Us",
    href: "#contact",
  },
];

export const Navigation = () => (
  <motion.ul className="menu-list" variants={variants}>
    {menuList.map(i => (
      <MenuItem i={i} key={i.label} />
    ))}
  </motion.ul>
);

