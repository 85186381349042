import { Space, Typography } from "antd";
import Image from "next/image";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

const descriptions = [
  "Founded by Dr. Dung Tong, a passionate educator with over 20 years of experience",
  "SMARK was born out of a desire to provide accessible and effective digital marketing solutions to businesses of all sizes",
  "Our team of experts combines deep digital marketing knowledge with a passion for technology to deliver groundbreaking solutions that empower businesses to thrive in the digital age.",
];

const words = [
  "AI Power-ed",
  "webflow development",
  "ecommerce",
  "3rd party integrations",
  "animation",
  "seo audit & optimization",
];
const delay = 2500;

const AboutUs = () => {
  const [items, setItems] = useState<string[]>([]);
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef<any>(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () => setIndex(prevIndex => (prevIndex === words.length - 1 ? 0 : prevIndex + 1)),
      delay,
    );

    if (index === 0) {
      setItems(["AI Power-ed"]);
    } else {
      setItems(prev => [...prev, words[index]]);
    }

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div
      id="about-us"
      className="h-[1300px] md:h-[1100px] lg:h-[926px] bg-[#F8F7EE] flex justify-center items-center lg:px-[50px] overflow-hidden py-[50px] lg:py-0"
    >
      <div className="max-w-[1340px] flex flex-col gap-8 lg:gap-0 lg:flex-row justify-center lg:justify-between items-center w-full h-full">
        <div className="flex flex-col gap-9 lg:max-w-[771px] px-3 lg:px-0">
          <Space direction="vertical" className="items-center lg:items-start">
            <Typography.Text className="text-base text-product-1 text-primary leading-6 font-bold uppercase">
              Get to know SMARK.
            </Typography.Text>
            <Typography.Title
              level={2}
              className="text-product-1 text-[30px] leading-[1.2] lg:text-[64px]  lg:leading-[77px] font-bold text-black-1m-0 text-center lg:text-left"
            >
              A TEAM OF SEASONED CREATIVES
            </Typography.Title>
          </Space>
          <div className="flex flex-col gap-6">
            {descriptions.map(i => (
              <div key={i} className="flex items-start gap-3">
                <Image src={"/check-icon.svg"} width={19.5} height={19.5} className="object-cover mt-1" alt="icon" />
                <Typography.Text className="text-product-2 text-sm leading-[1.2] lg:text-lg lg:leading-[24px] text-black-2">
                  {i}
                </Typography.Text>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full h-[650px] lg:h-auto lg:w-[423px] overflow-hidden">
          <AnimatePresence>
            <motion.ul
              className={classNames("flex flex-col gap-5 lg:gap-14", {
                "lg:last:mb-[230px]": index === words.length - 1,
              })}
            >
              {items.map((i, index) => (
                <motion.li
                  transition={{ type: "spring", mass: 1, stiffness: 100, damping: 15 }}
                  initial={{ x: -430 }}
                  animate={{ x: 0 }}
                  key={index}
                >
                  <Typography.Text
                    className={classNames(
                      "block text-test-schmal-bold font-bold italic text-[64px] leading-[1.2] lg:leading-[91px] text-center lg:text-[112px]  uppercase",
                      { "text-black-1": index % 2 === 0 },
                      { "text-primary": index % 2 !== 0 },
                    )}
                  >
                    {i}
                  </Typography.Text>
                </motion.li>
              ))}
            </motion.ul>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
