import { Typography } from "antd";
import Image from "next/image";
import { useRef } from "react";
import { motion } from "framer-motion";
const Game = () => {
  const scrollRef = useRef(null);
  return (
    <div ref={scrollRef} className="h-[700px] lg:h-[888px] bg-[#E79436] flex flex-col justify-center items-center gap-4 ">
      <Typography.Title
        level={5}
        className="text-2xl leading-[32px] m-0 text-white font-normal italic tracking-widest uppercase"
      >
        It’s a game
      </Typography.Title>
      <div className="relative md:max-w-[900px] text-center">
        <Typography.Text className="text-main-1 font-bold">SUCCES IS JUST A CLICK</Typography.Text>
        <motion.div
          initial={{ opacity: 0, top: "50%", left: "50%" }}
          whileInView={{ opacity: 1, top: "13%", left: "88%" }}
          className="absolute -translate-x-1/2 -translate-y-1/2"
        >
          <Image src="/game-icon.svg" width={106} height={103} className="object-cover" alt="game" />
        </motion.div>
      </div>
    </div>
  );
};
export default Game;
