import { Typography } from "antd";
import Image from "next/image";
import { motion, AnimatePresence } from "framer-motion";

const Solution = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 1,
      },
    },
  };

  const item1 = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
    transition: { type: "spring", mass: 1, stiffness: 100, damping: 15 },
  };

  const item2 = {
    hidden: { opacity: 0, y: -200 },
    show: { opacity: 1, y: 0 },
    transition: { type: "spring", mass: 1, stiffness: 100, damping: 15 },
  };

  const item3 = {
    hidden: { opacity: 0, x: -50 },
    show: { opacity: 1, x: 0 },
    transition: { type: "spring", mass: 1, stiffness: 100, damping: 15 },
  };
  return (
    <AnimatePresence mode="wait">
      <div
        id="solution"
        className="h-[596px] lg:h-[926px] bg-[#FFFFFF] flex justify-center items-center lg:px-[50px] my-[-50px] lg:my-0"
      >
        <motion.div
          variants={container}
          initial="hidden"
          whileInView="show"
          className="max-w-[390px] md:max-w-[680px] lg:max-w-[1230px] py-[18px] relative w-full h-full"
        >
          <motion.div
            variants={item2}
            initial={{ y: -200 }}
            animate={{ y: 0 }}
            className="object-cover absolute top-0 left-[-41%] md:top-[0%] md:left-[-25%] lg:top-[16%] lg:left-0 scale-[0.4] md:scale-[0.55] lg:scale-100"
          >
            <Image className="object-cover" src={"/solution/solution-1.svg"} width={704} height={598} alt="icon" />
          </motion.div>
          <motion.div
            variants={item3}
            className="object-cover absolute top-[36%] left-[11.2%] md:top-[55%] md:left-[13%]  lg:top-[67%] lg:left-[19.2%]  scale-50 md:scale-[0.55] lg:scale-100"
          >
            <Image className="object-cover" src={"/solution/solution-line-1.svg"} width={13} height={282} alt="icon" />
          </motion.div>
          <motion.div
            variants={item1}
            className="w-[250px] lg:w-[312px] absolute top-[55%] left-[-2%] md:top-[73%] md:left-[7%] lg:top-[84%] lg:left-[21%]  scale-50 md:scale-[0.55] lg:scale-100"
          >
            <Typography.Text className="text-product-2 font-normal text-sm leading-[21px] text-left text-black-2">
            Boost your business efficiency by streamlining communication from the contact center to every corner of your operations. 
            Gain valuable insights to address product concerns, refine marketing strategies, expedite processes, and achieve overall optimization.
            </Typography.Text>
          </motion.div>
          <motion.div
            variants={item1}
            className="absolute top-[22.4%] left-[35%] md:top-[26%] md:left-[38%] lg:top-[22%] lg:left-[45%]  scale-50 md:scale-[0.55] lg:scale-100"
          >
            <Image className="object-cover" src={"/solution/solution-step-1.svg"} width={154} height={52} alt="icon" />
          </motion.div>
          <motion.div
            variants={item2}
            className="absolute top-[-6%] right-[-37%] md:top-[-17%] md:right-[-23%] lg:top-0 lg:right-0 scale-[0.4] md:scale-[0.55] lg:scale-100"
          >
            <Image className="object-cover" src={"/solution/solution-2.svg"} width={741} height={666} alt="icon" />
          </motion.div>
          <motion.div
            variants={item3}
            className="absolute top-[26%] right-[32%] md:top-[42%] md:right-[34%]  lg:top-[56%] lg:right-[30%] scale-50 md:scale-[0.55] lg:scale-100"
          >
            <Image className="object-cover" src={"/solution/solution-line-2.svg"} width={13} height={374} alt="icon" />
          </motion.div>
          <motion.div
            variants={item1}
            className="w-[250px] lg:w-[312px] absolute top-[56%] right-[-16%] md:top-[72%] md:right-[5%]  lg:top-[83%] lg:right-[4%] scale-50 md:scale-[0.55] lg:scale-100"
          >
            <Typography.Text className="text-product-2 font-normal text-sm leading-[21px] text-left text-black-2">
            Leveraging a robust 40 billion parameter contact center LLM, SMARK extracts invaluable insights from every customer interaction. 
            Empowering your team to make informed decisions in real-time, it also automates workflows, enabling you to swiftly implement successful strategies throughout your organization. 
            Immediate impact, guaranteed.
            </Typography.Text>
          </motion.div>
          <motion.div
            variants={item2}
            className="absolute top-[21%] left-[-4%] md:top-[36%] md:left-[15%] lg:top-[46.5%] lg:left-[32%] scale-[0.4] md:scale-[0.55] lg:scale-100"
          >
            <Image className="object-cover" src={"/solution/solution-3.svg"} width={420} height={361} alt="icon" />
          </motion.div>
          <motion.div
            variants={item1}
            className="absolute top-[45%] left-[4%] md:top-[63%] md:left-[16%] lg:top-[66%] lg:left-[24%] scale-50 md:scale-[0.55] lg:scale-100"
          >
            <Image className="object-cover" src={"/solution/solution-step-2.svg"} width={154} height={52} alt="icon" />
          </motion.div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default Solution;
