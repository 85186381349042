import { Space, Typography } from "antd";
import Image from "next/image";
import Link from "next/link";
import { motion } from "framer-motion";
import { LINK_APP } from "@/constant/types";

const socials = [
  { icon: "/f-icon.svg", href: "" },
  { icon: "/i-icon.svg", href: "" },
  { icon: "/t-icon.svg", href: "" },
  { icon: "/y-icon.svg", href: "" },
];

const Footer = () => {
  const iconLeft = {
    rest: { left: "50%" },
    hover: {
      left: "150%",
      transition: {
        type: "spring",
        mass: 1,
        stiffness: 99.99,
        damping: 15,
      },
    },
  };

  const iconRight = {
    rest: { left: "-50%" },
    hover: {
      left: "50%",
      transition: {
        type: "spring",
        mass: 1,
        stiffness: 99.99,
        damping: 15,
      },
    },
  };
  return (
    <div id="contact" className="h-[1100px] lg:h-[1255px] bg-[#2000A1] flex flex-col justify-center items-center">
      <div className="flex flex-col gap-[72px] items-center flex-1 pt-[135px]">
        <div className="flex flex-col justify-center items-center gap-6 px-3 lg:px-0">
          <Typography.Title
            level={5}
            className="text-lg lg:text-2xl lg:leading-[32px] text-center m-0 text-white font-normal italic tracking-widest uppercase"
          >
            ready to learn more about smark
          </Typography.Title>
          <Typography.Text className="text-test-schmal-semibol text-[100px] leading-[1.2] lg:text-[180px] font-semibold lg:leading-[216px] tracking-wider text-center text-white uppercase">
            Let’s do this
          </Typography.Text>
          <Typography.Text className="text-base font-normal leading-6 text-center text-product-2 block md:max-w-[586px] text-white">
            Have a question or need assistance? We&apos;re here to help! Get in touch with our team via email, phone, or
            our online contact form. We&apos;re always available to answer your queries and provide personalised
            support.
          </Typography.Text>
        </div>
        <Link target="_blank" rel="noopener noreferrer" href={LINK_APP}>
          <motion.div
            initial="rest"
            whileHover="hover"
            animate="rest"
            className="footer-contact mx-3 lg:mx-0 lg:w-[1086px] lg:h-[164px] rounded-2xl border border-solid border-[#fff] py-6 px-5 lg:py-[58px] lg:px-[45px] flex items-center justify-between"
          >
            <Typography.Text className="text-xl lg:text-[40px] font-bold lg:leading-[48px] text-product-1 text-white uppercase">
              i’m ready, Let’s go
            </Typography.Text>
            <div className="relative w-[40px] h-[40px]  lg:w-[64px] lg:h-[100px] overflow-hidden">
              <motion.div
                variants={iconLeft}
                className="overflow-hidden absolute top-1/2 -translate-x-1/2 -translate-y-1/2"
              >
                <Image src={"/right-icon.svg"} width={36} height={30} alt="icon" />
              </motion.div>
              <motion.div
                variants={iconRight}
                className="overflow-hidden absolute top-1/2 -translate-x-1/2 -translate-y-1/2"
              >
                <Image src={"/right-icon.svg"} width={36} height={30} alt="icon" />
              </motion.div>
            </div>
          </motion.div>
        </Link>
      </div>
      <div className="w-full flex justify-center items-end md:max-w-[1340px]">
        <div className="py-8 lg:py-0 mx-3 lg:mx-0 w-full md:h-[391px] rounded-t-[36px] bg-white relative overflow-hidden flex flex-col gap-[54px] justify-center items-center">
          <div className="absolute w-1/2 h-full top-0 right-0">
            <Image src={"/footer-bg.png"} fill alt="bg" />
          </div>
          <Image
            className="object-cover w-1/2 h-1/2 md:w-auto md:h-auto"
            src={"/logo-footer.svg"}
            width={425}
            height={114}
            alt="icon"
          />
          <Space size={43} className="items-center z-50">
            {socials.map(i => (
              <Link key={i.icon} href={i.href}>
                <Image src={i.icon} width={40} height={40} alt="icon" />
              </Link>
            ))}
          </Space>
          <Typography.Text className="text-product-2 font-light text-sm text-[#6B7280] leading-[17px]">
            ©2023 Smark Technologies, Inc.
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default Footer;
