"use client";

import dynamic from "next/dynamic";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import AboutUs from "./components/AboutUs";
import Banner from "./components/Banner";
import Footer from "./components/Footer";
import QuestionAndAnswer from "./components/FQA";
import Game from "./components/Game";
import Header from "./components/Header";
import HowItWork from "./components/HowItWork";
import Innovation from "./components/Innovation";
import { Menu } from "./components/Menu";
import Service from "./components/Service";
import Solution from "./components/Solution";
const BlogSSR = dynamic(() => import("./components/Blog"), { ssr: false });
export default function Home() {
  return (
    <main className="min-h-screen">
      <Menu />
      <Header />
      <Banner />
      <Innovation />
      <AboutUs />
      <Service />
      <HowItWork />
      <Game />
      <Solution />
      <BlogSSR />
      {/* <Customer /> */}
      <QuestionAndAnswer />
      <Footer />
    </main>
  );
}
