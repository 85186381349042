import { LINK_APP } from "@/constant/types";
import { Space, Typography } from "antd";
import { motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
const HowItWork = () => (
  <div
    id="how-it-work"
    className="h-[1000px] md:h-[1300px] lg:h-[926px] bg-[#FFFFFF] flex justify-center items-center lg:px-[50px]"
  >
    <div className="max-w-[1340px] flex flex-col lg:flex-row justify-center items-center gap-[54px]">
      <div className="flex flex-col items-center lg:items-start gap-9 basis-2/5 px-3 lg:px-0">
        <Space direction="vertical" className="items-center lg:items-start">
          <Typography.Text className="text-base text-product-1 text-primary leading-6 font-bold uppercase">
            how it works
          </Typography.Text>
          <Typography.Title
            level={2}
            className="text-product-1 text-[42px] leading-[1.2] lg:text-[64px] text-center text-black-1 lg:leading-[77px] m-0 font-medium"
          >
            State-of-the-art intelligence
          </Typography.Title>
        </Space>
        <Typography.Text className="text-product-2 text-lg leading-[24px] text-black-2 text-justify lg:text-left">
        Wondering how SMARK can help you streamline your marketing workflow? Its simple! 
        Our platform utilises state-of-the-art artificial intelligence to automate the process of creating compelling marketing materials. 
        Just input your requirements, and let our AI algorithms do the rest. Whether you need a social media post, a promotional video, or a digital ad, SMARK has you covered.
        </Typography.Text>
        <Link target="_blank" rel="noopener noreferrer" href={LINK_APP}>
          <motion.div
            transition={{ type: "spring", mass: 1, stiffness: 266.7, damping: 10 }}
            whileHover={{ translateY: -5 }}
            className="flex items-center justify-center h-[56px] py-4 px-9 rounded-[60px] bg-primary text-white gap-3 cursor-pointer w-fit"
          >
            Start Achieving Your Business Goals
            <Image src={"/icon-direction.svg"} width={13.5} height={13.5} className="object-cover" alt="icon" />
          </motion.div>
        </Link>
      </div>
      <div className="basis-3/5  px-3 lg:px-0">
        <Image src={"/howitwork-bg.png"} width={778} height={563} className="object-cover" alt="bg" unoptimized />
      </div>
    </div>
  </div>
);
export default HowItWork;
