import { LINK_APP, LINK_GOOGLE } from "@/constant/types";
import { Space, Typography } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";

const Banner = () => (
  <div id="#banner" className="main-hero">
    <div className="relative flex justify-center items-center h-full">
      <Space size={0} direction="vertical" className="md:max-w-[966px] text-center items-center px-3 lg:px-0">
        <Typography.Title className="text-main-2 text-white m-0" level={1}>
          Welcome to SMARK
        </Typography.Title>
        <Typography.Text className="text-body-small text-white md:max-w-[826px] block mb-6">
          Where digital marketing meets innovation! Our platform leverages cutting-edge artificial intelligence to
          revolutionise the way businesses approach marketing. Whether you&apos;re a small business owner, a freelancer,
          or a marketing professional, SMARK is here to empower you with smart solutions that drive results.
        </Typography.Text>
        <div className="flex flex-col md:flex-row w-full gap-3 md:gap-6">
          <Link target="_blank" rel="noopener noreferrer" href={LINK_GOOGLE}>
            <motion.div
              transition={{ type: "spring", mass: 1, stiffness: 266.7, damping: 10 }}
              whileHover={{ translateY: -5 }}
              className="text-sm lg:text-base flex items-center justify-center h-[56px] py-4 px-9 rounded-[60px] bg-primary text-white gap-3 cursor-pointer"
            >
              Book A Demo
              <Image src={"/icon-direction.svg"} width={13.5} height={13.5} className="object-cover" alt="icon" />
            </motion.div>
          </Link>
          <Link target="_blank" rel="noopener noreferrer" href={LINK_APP}>
            <motion.div
              transition={{ type: "spring", mass: 1, stiffness: 266.7, damping: 10 }}
              whileHover={{ translateY: -5 }}
              className="text-sm lg:text-base flex items-center justify-center h-[56px] py-4 px-9 rounded-[60px] bg-secondary-2 text-white gap-3 cursor-pointer"
            >
              Customer Portal
              <Image src={"/icon-direction.svg"} width={13.5} height={13.5} className="object-cover" alt="icon" />
            </motion.div>
          </Link>
        </div>
      </Space>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, y: -5 }}
          exit={{ opacity: 0 }}
          transition={{
            repeat: Infinity,
            mass: 1,
            stiffness: 100,
            damping: 15,
            delay: 1,
            repeatDelay: 1,
            repeatType: "mirror",
          }}
          className="absolute bottom-8"
        >
          <Image src={"/dropdown-icon.svg"} width={24.75} height={13.5} alt="icon" />
        </motion.div>
      </AnimatePresence>
    </div>
  </div>
);
export default Banner;
