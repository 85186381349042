import * as React from "react";
import { motion } from "framer-motion";
import Link from "next/link";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const MenuItem = ({ i }: { i: { label: string; href: string } }) => (
    <motion.li className="menu-item" variants={variants} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
      <Link className="w-full px-3 py-2 h-10 text-black-1 text-base nav-link rounded-lg" href={i.href}>
        {i.label}
      </Link>
    </motion.li>
  );
