import * as React from "react";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "@/app/utils/useDimensions";
import { Navigation } from "./Navigate";
import { MenuToggle } from "./MenuToggle";
import "../../styles/menu.scss";
import Image from "next/image";
import classNames from "classnames";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

export const Menu = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      className={classNames("mobile-memu", { "z-50 w-[300px]": isOpen })}
    >
      <motion.div className="menu-background" variants={sidebar}>
        <Image
          className="object-cover absolute top-[18px] right-[30px]"
          src="/logo.svg"
          width={168}
          height={45}
          alt="logo"
        />
      </motion.div>
      <Navigation />
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  );
};
